// src/components/landing/TrustBadges.jsx
import { Shield, MessageSquare, Video } from 'lucide-react';

const TrustBadges = () => {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-8">
      <div className="flex flex-wrap justify-center items-center gap-8 text-gray-400">
        <div className="flex items-center gap-2">
          <Shield className="w-5 h-5" />
          <span>256-bit Encryption</span>
        </div>
        <div className="flex items-center gap-2">
          <MessageSquare className="w-5 h-5" />
          <span>10k+ Questions Asked</span>
        </div>
        <div className="flex items-center gap-2">
          <Video className="w-5 h-5" />
          <span>HD Video Responses</span>
        </div>
      </div>
    </div>
  );
};

export default TrustBadges;
// src/components/questions/QuestionFormCard.jsx
import { Shield, Info } from 'lucide-react';
import { useState } from 'react';
import PropTypes from 'prop-types';

const QuestionFormCard = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    question: '',
    recipient: '',
    recipientEmail: '',
    recipientContact: 'email' // default contact method
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="bg-white p-6 rounded-2xl shadow-xl border border-purple-100">
      <h2 className="text-2xl font-semibold text-purple-900 mb-6">Ask Your Question</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Who is this question for?
          </label>
          <input
            type="text"
            name="recipient"
            value={formData.recipient}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
            placeholder="e.g., 'My Team Lead' or 'Professor Smith'"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            How should we contact them?
          </label>
          <div className="space-y-4">
            <div>
              <div className="flex items-center mb-2">
                <input
                  type="radio"
                  id="email"
                  name="recipientContact"
                  value="email"
                  checked={formData.recipientContact === 'email'}
                  onChange={handleInputChange}
                  className="w-4 h-4 text-purple-600 border-gray-300 focus:ring-purple-500"
                />
                <label htmlFor="email" className="ml-2 block text-sm text-gray-600">
                  Email
                </label>
              </div>
              {formData.recipientContact === 'email' && (
                <input
                  type="email"
                  name="recipientEmail"
                  value={formData.recipientEmail}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
                  placeholder="their.email@example.com"
                  required
                />
              )}
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Your Question
          </label>
          <textarea
            name="question"
            value={formData.question}
            onChange={handleInputChange}
            rows={4}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
            placeholder="Type your question here..."
            required
          />
        </div>

        {/* Anonymity Tips */}
        <div className="bg-purple-50 rounded-lg p-4 text-sm">
          <div className="flex items-center gap-2 mb-2">
            <Shield className="w-4 h-4 text-purple-600" />
            <span className="font-medium text-purple-900">Quick Tips</span>
          </div>
          <ul className="text-gray-600 space-y-1">
            <li>• Avoid specific dates or events</li>
            <li>• Don't mention unique characteristics</li>
            <li>• Keep it general and focused</li>
          </ul>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center gap-2 font-semibold"
        >
          Submit Question
        </button>
      </form>
    </div>
  );
};

QuestionFormCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default QuestionFormCard;
// src/components/landing/Testimonials.jsx
import { Star } from 'lucide-react';

const Testimonials = () => {
  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Tech Professional",
      content: "Got answers to questions I was too shy to ask in person. This platform is a game-changer!",
      rating: 5
    },
    {
      name: "Michael Chen",
      role: "Student",
      content: "The video responses were detailed and helpful. Worth every penny!",
      rating: 5
    },
    {
      name: "Emily Rodriguez",
      role: "Marketing Manager",
      content: "Finally got clarity on some career questions. The anonymity really helps!",
      rating: 4
    }
  ];

  const renderStars = (rating) => {
    return [...Array(rating)].map((_, i) => (
      <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
    ));
  };

  return (
    <div id="testimonials" className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-16">
      <h2 className="text-2xl sm:text-3xl font-bold text-center text-purple-900 mb-12">
        What People Say
      </h2>
      <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex mb-4">
              {renderStars(testimonial.rating)}
            </div>
            <p className="text-gray-600 mb-4">{testimonial.content}</p>
            <div>
              <p className="font-semibold">{testimonial.name}</p>
              <p className="text-sm text-gray-500">{testimonial.role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
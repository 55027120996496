// src/components/landing/Pricing.jsx
import { Shield, MessageSquare, Video } from 'lucide-react';

const Pricing = ({ handleAskQuestion }) => {
  const plans = [
    {
      title: "Written Response",
      description: "Get your answer in written form",
      price: "5",
      features: [
        { icon: Shield, text: "Anonymous delivery" },
        { icon: MessageSquare, text: "Detailed written response" }
      ]
    },
    {
      title: "Video Response",
      description: "Get your answer in video format",
      price: "10",
      features: [
        { icon: Shield, text: "Anonymous delivery" },
        { icon: Video, text: "HD video response" }
      ]
    }
  ];

  return (
    <div id="pricing" className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-16">
      <h2 className="text-2xl sm:text-3xl font-bold text-center text-purple-900 mb-12">
        Simple Pricing
      </h2>
      <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
        {plans.map((plan, index) => (
          <div 
            key={index}
            className="bg-white p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow border-2 border-purple-100"
          >
            <h3 className="text-xl font-semibold mb-2">{plan.title}</h3>
            <p className="text-gray-600 mb-4">{plan.description}</p>
            <div className="text-3xl font-bold text-purple-900 mb-6">
              ${plan.price}<span className="text-lg text-gray-600">/question</span>
            </div>
            <ul className="space-y-3 mb-8">
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex} className="flex items-center gap-2">
                  <feature.icon className="w-4 h-4 text-purple-600" />
                  <span>{feature.text}</span>
                </li>
              ))}
            </ul>
            <button 
              onClick={handleAskQuestion}
              className="w-full bg-purple-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-purple-700 transition-colors"
            >
              Get Started
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
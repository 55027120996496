// src/components/landing/Features.jsx
import { Shield, DollarSign } from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: Shield,
      title: "100% Anonymous",
      description: "Your identity remains completely private throughout the process."
    },
    {
      icon: DollarSign,
      title: "Secure Payments",
      description: "Money is only released when you receive your answer."
    }
  ];

  return (
    <div id="features" className="bg-purple-50 py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-2xl sm:text-3xl font-bold text-center text-purple-900 mb-12">
            Why Choose SnarkyQuestions?
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="flex gap-4">
                <div className="bg-purple-100 w-12 h-12 rounded-full flex items-center justify-center flex-shrink-0">
                  <feature.icon className="w-6 h-6 text-purple-600" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
// src/components/landing/Navigation.jsx
import { MessageSquare, Menu, X } from 'lucide-react';

const Navigation = ({ isMenuOpen, setIsMenuOpen, handleAskQuestion }) => {
  return (
    <nav className="fixed top-0 w-full bg-white/80 backdrop-blur-md shadow-sm z-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex items-center gap-2">
              <MessageSquare className="w-8 h-8 text-purple-600" />
              <span className="text-xl font-bold text-purple-900">SnarkyQ</span>
            </div>
          </div>
          <div className="hidden md:flex items-center gap-8">
            <a href="#how-it-works" className="text-gray-600 hover:text-purple-600 transition-colors">How It Works</a>
            <a href="#features" className="text-gray-600 hover:text-purple-600 transition-colors">Features</a>
            <a href="#pricing" className="text-gray-600 hover:text-purple-600 transition-colors">Pricing</a>
            <button 
              onClick={handleAskQuestion}
              className="bg-purple-600 text-white px-4 py-2 rounded-full text-sm font-semibold hover:bg-purple-700 transition-colors"
            >
              Login / Signup
            </button>
          </div>
          <div className="md:hidden">
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-purple-600 transition-colors"
            >
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden bg-white border-t border-gray-100">
          <div className="container mx-auto px-4 py-4 space-y-3">
            <a href="#how-it-works" className="block text-gray-600 hover:text-purple-600 transition-colors">How It Works</a>
            <a href="#features" className="block text-gray-600 hover:text-purple-600 transition-colors">Features</a>
            <a href="#pricing" className="block text-gray-600 hover:text-purple-600 transition-colors">Pricing</a>
            <button 
              onClick={handleAskQuestion}
              className="w-full bg-purple-600 text-white px-4 py-2 rounded-full text-sm font-semibold hover:bg-purple-700 transition-colors"
            >
              Get Started
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
// src/config/stripe.js
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

// Validate Stripe key is loaded
if (!import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY) {
  console.error('Missing Stripe publishable key in environment variables');
}

export default stripePromise;
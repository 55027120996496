// src/components/landing/HowItWorks.jsx
import { MessageSquare, Shield, Video } from 'lucide-react';

const HowItWorks = () => {
  const steps = [
    {
      icon: MessageSquare,
      title: "1. Submit Your Question",
      description: "Write your question and specify who you want to ask. Set your price for the answer."
    },
    {
      icon: Shield,
      title: "2. We Handle Delivery",
      description: "We'll anonymously reach out to the person and handle the payment process securely."
    },
    {
      icon: Video,
      title: "3. Get Your Answer",
      description: "Receive their response via video or written format, maintaining your anonymity."
    }
  ];

  return (
    <div id="how-it-works" className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-16">
      <h2 className="text-2xl sm:text-3xl font-bold text-center text-purple-900 mb-12">How It Works</h2>
      <div className="grid md:grid-cols-3 gap-6 sm:gap-8 max-w-4xl mx-auto">
        {steps.map((step, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
            <div className="bg-purple-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
              <step.icon className="w-6 h-6 text-purple-600" />
            </div>
            <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
            <p className="text-gray-600">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
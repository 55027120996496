// PaymentMethods.jsx
import { useState } from 'react';
import { CreditCard, AlertCircle } from 'lucide-react';

const PaymentMethods = ({ 
  loading = false,
  isDev = false,
  children 
}) => {
  const [selectedMethod] = useState('stripe');

  return (
    <div className="space-y-6">
      <div>
        <div className={`w-full p-4 rounded-lg border-2 transition-colors flex items-center gap-4
          ${selectedMethod === 'stripe' ? 'border-purple-600 bg-purple-50' : 'border-gray-200'}`}
        >
          <div className="p-2 rounded-full bg-purple-100">
            <CreditCard className="w-5 h-5 text-purple-600" />
          </div>
          <div className="flex-1 text-left">
            <p className="font-medium">Credit or Debit Card</p>
            <div className="flex items-center gap-2 mt-2">
              <div className="flex space-x-2">
                <div className="h-6 px-2 bg-gray-100 rounded flex items-center">
                  <span className="text-xs font-medium text-gray-600">VISA</span>
                </div>
                <div className="h-6 px-2 bg-gray-100 rounded flex items-center">
                  <span className="text-xs font-medium text-gray-600">MASTERCARD</span>
                </div>
                <div className="h-6 px-2 bg-gray-100 rounded flex items-center">
                  <span className="text-xs font-medium text-gray-600">AMEX</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6">
        <div className="space-y-4">
          {isDev && (
            <div className="bg-purple-50 p-4 rounded-lg">
              <div className="flex items-start gap-2">
                <AlertCircle className="w-4 h-4 text-purple-600 mt-0.5" />
                <div className="text-sm text-purple-600">
                  <p className="font-medium">Test Card Details:</p>
                  <ul className="mt-1 space-y-1">
                    <li>• Success: 4242 4242 4242 4242</li>
                    <li>• Requires Auth: 4000 0025 0000 3155</li>
                    <li>• Decline: 4000 0000 0000 9995</li>
                    <li>• Use any future date & any 3-digit CVC</li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {children}
          <div className="flex flex-col items-center justify-center gap-2 pt-4 border-t border-gray-100">
            <div className="flex items-center gap-2">
              <CreditCard className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-500">Secure payment powered by</span>
            </div>
            <div className="text-[#635BFF] font-medium text-lg tracking-tight">stripe</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
// src/pages/DashboardPage.jsx
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  orderBy 
} from 'firebase/firestore';
import { db } from '../config/firebase';
import AuthenticatedHeader from '../components/auth/AuthenticatedHeader';
import { Loader, AlertCircle, MessageSquare, DollarSign, Clock } from 'lucide-react';

const DashboardPage = ({ user, onSignOut }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeQuestions, setActiveQuestions] = useState([]);
  const [stats, setStats] = useState({
    totalSpent: 0,
    totalQuestions: 0,
    answeredQuestions: 0
  });

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // Create query for pending questions
        const questionsRef = collection(db, 'questions');
        const q = query(
          questionsRef,
          where('userId', '==', user.uid),
          where('status', '==', 'pending'),
          orderBy('createdAt', 'desc')
        );

        // Get the questions
        const querySnapshot = await getDocs(q);
        const questions = [];
        let totalAmount = 0;

        querySnapshot.forEach((doc) => {
          questions.push({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate() // Convert Firestore Timestamp to Date
          });
          totalAmount += doc.data().price || 0;
        });

        setActiveQuestions(questions);

        // Fetch statistics
        const statsQuery = query(
          questionsRef,
          where('userId', '==', user.uid)
        );
        const statsSnapshot = await getDocs(statsQuery);
        
        let totalQuestions = 0;
        let answeredQuestions = 0;
        let totalSpent = 0;

        statsSnapshot.forEach((doc) => {
          totalQuestions++;
          if (doc.data().status === 'answered') answeredQuestions++;
          totalSpent += doc.data().price || 0;
        });

        setStats({
          totalSpent,
          totalQuestions,
          answeredQuestions
        });

      } catch (error) {
        console.error('Error fetching questions:', error);
        setError('Failed to load your questions. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchQuestions();
    }
  }, [user?.uid]);

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const getTimeAgo = (date) => {
    if (!date) return '';
    const seconds = Math.floor((new Date() - date) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
    };

    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
      const intervalInUnits = Math.floor(seconds / secondsInUnit);
      if (intervalInUnits > 0) {
        return `${intervalInUnits} ${unit}${intervalInUnits === 1 ? '' : 's'} ago`;
      }
    }
    return 'Just now';
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AuthenticatedHeader user={user} onSignOut={onSignOut} />
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl pt-20">
          <div className="flex items-center justify-center h-64">
            <Loader className="w-8 h-8 text-purple-600 animate-spin" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AuthenticatedHeader user={user} onSignOut={onSignOut} />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl pt-20">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <div className="flex items-center gap-4">
              <div className="bg-purple-100 p-3 rounded-lg">
                <MessageSquare className="w-6 h-6 text-purple-600" />
              </div>
              <div>
                <p className="text-sm text-gray-600">Active Questions</p>
                <p className="text-2xl font-bold text-gray-900">{activeQuestions.length}</p>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <div className="flex items-center gap-4">
              <div className="bg-green-100 p-3 rounded-lg">
                <DollarSign className="w-6 h-6 text-green-600" />
              </div>
              <div>
                <p className="text-sm text-gray-600">Total Spent</p>
                <p className="text-2xl font-bold text-gray-900">${stats.totalSpent}</p>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <div className="flex items-center gap-4">
              <div className="bg-blue-100 p-3 rounded-lg">
                <Clock className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <p className="text-sm text-gray-600">Answer Rate</p>
                <p className="text-2xl font-bold text-gray-900">
                  {stats.totalQuestions > 0 
                    ? Math.round((stats.answeredQuestions / stats.totalQuestions) * 100)
                    : 0}%
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Active Questions Section */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6 border-b border-gray-100">
            <h2 className="text-xl font-semibold text-gray-900">Active Questions</h2>
          </div>

          {error && (
            <div className="p-6 flex items-center gap-2 text-red-600 bg-red-50">
              <AlertCircle className="w-5 h-5" />
              <p>{error}</p>
            </div>
          )}

          {!error && activeQuestions.length === 0 ? (
            <div className="p-6 text-center text-gray-500">
              <MessageSquare className="w-12 h-12 mx-auto mb-4 text-gray-400" />
              <p className="text-lg font-medium mb-2">No Active Questions</p>
              <p className="text-sm text-gray-400">
                Your questions will appear here once you submit them
              </p>
            </div>
          ) : (
            <div className="divide-y divide-gray-100">
              {activeQuestions.map((question) => (
                <div key={question.id} className="p-6 hover:bg-gray-50 transition-colors">
                  <div className="flex items-start justify-between mb-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900 mb-1">
                        {question.question}
                      </h3>
                      <p className="text-sm text-gray-500">
                        For: {question.recipient} ({question.recipientEmail})
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-lg font-semibold text-purple-600">
                        ${question.price}
                      </p>
                      <p className="text-sm text-gray-500">
                        {question.responseFormat} response
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <p>Submitted {getTimeAgo(question.createdAt)}</p>
                    <p>Status: <span className="text-yellow-600 font-medium">Pending</span></p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DashboardPage.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  onSignOut: PropTypes.func.isRequired
};

export default DashboardPage;
import { useState } from 'react';
import { Shield, Info, Video, MessageSquare, Mic, HelpCircle, DollarSign, AlertCircle } from 'lucide-react';
import PropTypes from 'prop-types';
import { calculatePlatformFee } from '../../utils/pricing';

const DetailedQuestionForm = ({ 
  onSubmit, 
  initialData = null, 
  isSubmitting = false 
}) => {
  const [formData, setFormData] = useState({
    question: initialData?.question || '',
    recipient: initialData?.recipient || '',
    recipientEmail: initialData?.recipientEmail || '',
    recipientContact: initialData?.recipientContact || 'email',
    responseFormat: 'written',
    price: 5,
    isAnonymous: true,
    status: 'pending',
    paymentStatus: 'pending',
  });

  const [validationErrors, setValidationErrors] = useState({});

  const responseFormats = [
    {
      id: 'written',
      icon: MessageSquare,
      label: 'Written Response',
      description: 'Detailed written answer to your question',
      basePrice: 5
    },
    {
      id: 'audio',
      icon: Mic,
      label: 'Audio Response',
      description: 'Voice recording answer',
      basePrice: 8
    },
    {
      id: 'video',
      icon: Video,
      label: 'Video Response',
      description: 'Face-to-face video answer',
      basePrice: 12
    }
  ];

  const validateForm = () => {
    const errors = {};
    
    if (!formData.question.trim()) {
      errors.question = 'Question is required';
    }
    if (!formData.recipient.trim()) {
      errors.recipient = 'Recipient name is required';
    }
    if (!formData.recipientEmail.trim()) {
      errors.recipientEmail = 'Recipient email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.recipientEmail)) {
      errors.recipientEmail = 'Please enter a valid email address';
    }
    if (formData.price < responseFormats.find(f => f.id === formData.responseFormat)?.basePrice) {
      errors.price = `Minimum price for ${formData.responseFormat} format is $${responseFormats.find(f => f.id === formData.responseFormat)?.basePrice}`;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const platformFee = calculatePlatformFee(formData.price);
      
      const questionData = {
        ...formData,
        platformFee,
        status: 'pending',
        paymentStatus: 'pending',
        response: null,
        respondedAt: null,
        isAnonymous: true,
        systemMetadata: {
          version: '1.0',
          platform: 'web',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      };

      onSubmit(questionData);
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Recipient Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-semibold mb-4">Who is this question for?</h3>
          
          {/* Recipient Name */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Recipient Name or Title
            </label>
            <input
              type="text"
              value={formData.recipient}
              onChange={(e) => setFormData({ ...formData, recipient: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
              placeholder="e.g. 'Professor Smith' or 'Team Lead'"
            />
            {validationErrors.recipient && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.recipient}</p>
            )}
          </div>
          
          {/* Recipient Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Recipient Email
            </label>
            <input
              type="email"
              value={formData.recipientEmail}
              onChange={(e) => setFormData({ ...formData, recipientEmail: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
              placeholder="their.email@example.com"
            />
            {validationErrors.recipientEmail && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.recipientEmail}</p>
            )}
          </div>
        </div>

        {/* Question Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-semibold mb-4">Your Question</h3>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              What would you like to ask?
            </label>
            <textarea
              value={formData.question}
              onChange={(e) => setFormData({ ...formData, question: e.target.value })}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
              placeholder="Type your question here..."
            />
            {validationErrors.question && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.question}</p>
            )}
          </div>

          {/* Privacy Notice */}
          <div className="bg-purple-50 rounded-lg p-4">
            <div className="flex items-center gap-2 mb-2">
              <Shield className="w-4 h-4 text-purple-600" />
              <span className="font-medium text-purple-900">Privacy Tips</span>
            </div>
            <ul className="text-sm text-gray-600 space-y-1">
              <li>• Avoid mentioning specific dates or events that could identify you</li>
              <li>• Don't reference personal interactions or private conversations</li>
              <li>• Keep the question focused and professional</li>
            </ul>
          </div>
        </div>

        {/* Response Format Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-semibold mb-4">Response Format</h3>
          
          <div className="grid sm:grid-cols-3 gap-4 mb-6">
            {responseFormats.map((format) => (
              <button
                key={format.id}
                type="button"
                onClick={() => setFormData(prev => ({
                  ...prev,
                  responseFormat: format.id,
                  price: Math.max(prev.price, format.basePrice)
                }))}
                className={`p-4 rounded-lg border-2 text-left transition-colors ${
                  formData.responseFormat === format.id
                    ? 'border-purple-600 bg-purple-50'
                    : 'border-gray-200 hover:border-purple-600'
                }`}
              >
                <format.icon className={`w-6 h-6 mb-2 ${
                  formData.responseFormat === format.id ? 'text-purple-600' : 'text-gray-400'
                }`} />
                <h4 className="font-medium mb-1">{format.label}</h4>
                <p className="text-sm text-gray-600">{format.description}</p>
                <p className="text-sm font-medium text-purple-600 mt-2">
                  From ${format.basePrice}
                </p>
              </button>
            ))}
          </div>

          {/* Custom Price Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Set Your Price
              <div className="inline-flex items-center ml-2">
                <HelpCircle className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-help" />
                <span className="sr-only">Higher prices may increase response likelihood</span>
              </div>
            </label>
            <div className="relative">
              <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="number"
                min={responseFormats.find(f => f.id === formData.responseFormat)?.basePrice}
                step="0.01"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: parseFloat(e.target.value) })}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
              />
            </div>
            {validationErrors.price && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.price}</p>
            )}
            <p className="text-sm text-gray-500 mt-1">
              Higher prices may increase the likelihood of getting a response
            </p>
          </div>
        </div>

        {/* Submit Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          {/* Show any form-wide validation errors */}
          {Object.keys(validationErrors).length > 0 && (
            <div className="mb-4 bg-red-50 text-red-600 p-4 rounded-lg">
              <div className="flex items-center gap-2">
                <AlertCircle className="w-5 h-5" />
                <p className="font-medium">Please fix the following errors:</p>
              </div>
              <ul className="list-disc list-inside mt-2 text-sm">
                {Object.values(validationErrors).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Platform Fee Info */}
          <div className="mb-6">
            <div className="flex justify-between text-sm mb-1">
              <span className="text-gray-600">Question Price:</span>
              <span className="font-medium">${formData.price}</span>
            </div>
            <div className="flex justify-between text-sm mb-2">
              <span className="text-gray-600">Platform Fee:</span>
              <span className="font-medium">${calculatePlatformFee(formData.price)}</span>
            </div>
            <div className="flex justify-between font-medium">
              <span>Total:</span>
              <span className="text-purple-600">
                ${(formData.price + calculatePlatformFee(formData.price)).toFixed(2)}
              </span>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full py-3 rounded-lg text-white font-semibold flex items-center justify-center gap-2
              ${isSubmitting 
                ? 'bg-purple-400 cursor-not-allowed' 
                : 'bg-purple-600 hover:bg-purple-700'} 
              transition-colors`}
          >
            {isSubmitting ? (
              <>
                <span className="animate-spin">⟳</span>
                Processing...
              </>
            ) : (
              'Continue to Payment'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

DetailedQuestionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    question: PropTypes.string,
    recipient: PropTypes.string,
    recipientEmail: PropTypes.string,
    recipientContact: PropTypes.string
  }),
  isSubmitting: PropTypes.bool
};

export default DetailedQuestionForm;
// src/pages/PaymentSuccessPage.jsx
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { CheckCircle, ArrowRight } from 'lucide-react';
import AuthenticatedHeader from '../components/auth/AuthenticatedHeader';
import PropTypes from 'prop-types';

const PaymentSuccessPage = ({ user, onSignOut }) => {
  const { paymentIntentId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questionData, setQuestionData] = useState(null);

  useEffect(() => {
    const updatePaymentStatus = async () => {
      try {
        if (paymentIntentId) {
          // Query for the question with this payment intent
          const querySnapshot = await getDocs(
            query(
              collection(db, 'questions'),
              where('paymentIntent', '==', paymentIntentId),
              where('userId', '==', user.uid),
              limit(1)
            )
          );

          if (!querySnapshot.empty) {
            const questionDoc = querySnapshot.docs[0];
            const data = questionDoc.data();

            // Update the question status
            await updateDoc(doc(db, 'questions', questionDoc.id), {
              status: 'pending',
              paymentStatus: 'completed',
              updatedAt: serverTimestamp()
            });

            setQuestionData(data);
          }
        }
      } catch (err) {
        console.error('Error updating payment status:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    updatePaymentStatus();
  }, [paymentIntentId, user.uid]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AuthenticatedHeader user={user} onSignOut={onSignOut} />
        <div className="pt-20 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-600"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AuthenticatedHeader user={user} onSignOut={onSignOut} />
      
      <div className="pt-20 container mx-auto px-4 max-w-md">
        <div className="bg-white rounded-2xl shadow-xl p-8 text-center">
          <div className="mb-6">
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto" />
          </div>
          
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Payment Successful!
          </h1>
          
          <p className="text-gray-600 mb-8">
            Your question has been submitted and we'll notify you once it's answered.
          </p>

          <button
            onClick={() => navigate('/dashboard')}
            className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center gap-2 w-full"
          >
            Go to Dashboard
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

PaymentSuccessPage.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onSignOut: PropTypes.func.isRequired
};

export default PaymentSuccessPage;
// src/utils/pricing.js
export const calculatePlatformFee = (price) => {
    // Base fee is 10% of the price
    const baseFee = price * 0.10;
    
    // Minimum fee is $0.50
    const minFee = 0.50;
    
    // Use the higher of base fee or minimum fee
    const fee = Math.max(baseFee, minFee);
    
    // Round to 2 decimal places
    return Math.round(fee * 100) / 100;
  };
  
  export const calculateTotal = (price, platformFee) => {
    return Math.round((price + platformFee) * 100) / 100;
  };
  
  export const getPriceByResponseFormat = {
    written: 5,
    audio: 8,
    video: 12
  };
import { useState } from 'react';
import ProfileConversations from '../components/profile/ProfileConversations';
import ProfileSettings from '../components/profile/ProfileSettings';

const ProfilePage = ({ user, onUpdateProfile }) => {
  // Mock data for MVP - Replace with real data in production
  const [conversations] = useState([
    {
      id: 1,
      questionTitle: "How to implement WebSocket in React?",
      lastMessage: "Thank you for the detailed explanation! One more question...",
      timestamp: "2 hours ago",
      unread: true,
      expert: {
        name: "John Doe",
        avatar: "/api/placeholder/32/32"
      }
    },
    {
      id: 2,
      questionTitle: "Best practices for React state management",
      lastMessage: "That makes sense. I'll implement your suggestions.",
      timestamp: "1 day ago",
      unread: false,
      expert: {
        name: "Jane Smith",
        avatar: "/api/placeholder/32/32"
      }
    }
  ]);

  const handleConversationClick = (conversation) => {
    // Navigate to conversation or open chat
    console.log('Opening conversation:', conversation.id);
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-20 pb-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-4xl">
        <div className="space-y-6">
          {/* Header */}
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Profile</h1>
            <p className="mt-1 text-sm text-gray-600">
              Manage your account settings and messages
            </p>
          </div>

          {/* Conversations Section */}
          <ProfileConversations 
            conversations={conversations}
            onConversationClick={handleConversationClick}
          />

          {/* Settings Section */}
          <ProfileSettings 
            user={user}
            onUpdateProfile={onUpdateProfile}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
// components/checkout/OrderSummary.jsx
import { useState } from 'react';
import { Clock, MessageSquare, Mail } from 'lucide-react';
import PropTypes from 'prop-types';

const OrderSummary = ({ questionData, loading }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (loading) {
    return (
      <div className="bg-purple-50 p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-purple-100 rounded w-1/3"></div>
          <div className="bg-white rounded-lg p-4 space-y-3">
            <div className="h-4 bg-purple-100 rounded w-1/4"></div>
            <div className="h-20 bg-purple-100 rounded"></div>
          </div>
          <div className="bg-white rounded-lg p-4 space-y-3">
            <div className="h-4 bg-purple-100 rounded w-1/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-purple-100 rounded"></div>
              <div className="h-4 bg-purple-100 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-purple-50 p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-purple-900">Order Summary</h1>
        <div className="flex items-center gap-2">
          <Clock className="w-4 h-4 text-purple-600" />
          <span className="text-sm text-purple-600">Expected response: 24-48 hours</span>
        </div>
      </div>

      <div className="space-y-4">
        {/* Question Details */}
        <div className="bg-white rounded-lg p-4 space-y-3">
          <h3 className="font-medium text-gray-900 flex items-center gap-2">
            <MessageSquare className="w-4 h-4 text-purple-600" />
            Question Details
          </h3>
          <div className="pl-6">
            <p 
              className="text-gray-600 text-sm cursor-pointer hover:text-purple-600"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? questionData?.question : `${questionData?.question?.substring(0, 100)}...`}
            </p>
            <div className="flex justify-between items-center mt-2 text-sm">
              <span className="text-gray-500">Response Type:</span>
              <span className="font-medium">{questionData?.responseFormat || 'Written Response'}</span>
            </div>
          </div>
        </div>

        {/* Recipient Information */}
        <div className="bg-white rounded-lg p-4 space-y-3">
          <h3 className="font-medium text-gray-900 flex items-center gap-2">
            <Mail className="w-4 h-4 text-purple-600" />
            Recipient Information
          </h3>
          <div className="pl-6 space-y-2">
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-500">Name/Title:</span>
              <span className="font-medium">{questionData?.recipient}</span>
            </div>
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-500">Email:</span>
              <span className="font-medium">{questionData?.recipientEmail}</span>
            </div>
          </div>
        </div>

        {/* Price Summary */}
        <div className="bg-white rounded-lg p-4">
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Base Price:</span>
              <span>${questionData?.price?.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Platform Fee:</span>
              <span>${questionData?.platformFee?.toFixed(2)}</span>
            </div>
            <div className="border-t border-gray-100 mt-2 pt-2 flex justify-between font-semibold">
              <span>Total:</span>
              <span className="text-purple-600">
                ${((questionData?.price || 0) + (questionData?.platformFee || 0)).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  questionData: PropTypes.shape({
    question: PropTypes.string,
    recipient: PropTypes.string,
    recipientEmail: PropTypes.string,
    responseFormat: PropTypes.string,
    price: PropTypes.number,
    platformFee: PropTypes.number,
  }), loading: PropTypes.bool
};


export default OrderSummary;
import { MessageSquare, ChevronRight } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/Card"

const ProfileConversations = ({ conversations = [], onConversationClick }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <MessageSquare className="w-5 h-5 text-purple-600" />
          Conversations
        </CardTitle>
        <CardDescription>
          Continue discussions about your questions
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="divide-y">
          {conversations.length > 0 ? (
            conversations.map((conversation) => (
              <button
                key={conversation.id}
                className="w-full py-4 text-left hover:bg-gray-50 transition-colors group"
                onClick={() => onConversationClick?.(conversation)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <img
                      src={conversation.expert.avatar}
                      alt={conversation.expert.name}
                      className="w-8 h-8 rounded-full bg-gray-200"
                    />
                    <div className="flex-1 min-w-0"> {/* Added min-w-0 for text truncation */}
                      <h3 className="font-medium text-gray-900 flex items-center gap-2 truncate">
                        {conversation.questionTitle}
                        {conversation.unread && (
                          <span className="w-2 h-2 bg-purple-600 rounded-full flex-shrink-0"></span>
                        )}
                      </h3>
                      <p className="text-sm text-gray-500 line-clamp-1">
                        {conversation.lastMessage}
                      </p>
                      <p className="text-xs text-gray-400 mt-1">
                        {conversation.timestamp} • {conversation.expert.name}
                      </p>
                    </div>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-gray-600 flex-shrink-0" />
                </div>
              </button>
            ))
          ) : (
            <div className="py-6 text-center text-gray-500">
              <MessageSquare className="w-8 h-8 mx-auto mb-2 text-gray-400" />
              <p>No conversations yet</p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProfileConversations;
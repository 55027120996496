import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from '../../server/config/stripe';
import { Shield, ArrowLeft, AlertCircle } from 'lucide-react';
import { getAuth } from 'firebase/auth';
import AuthenticatedHeader from '../components/auth/AuthenticatedHeader';
import PaymentForm from '../components/payment/PaymentForm';
import PaymentMethods from '../components/payment/PaymentMethods';
import OrderSummary from '../components/payment/OrderSummary';
import PropTypes from 'prop-types';

const CheckoutPage = ({ user, onSignOut }) => {
  const { questionId } = useParams();
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [isDev] = useState(import.meta.env.MODE === 'development');

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        if (!questionId) {
          throw new Error('No question ID provided');
        }

        if (!user?.uid) {
          throw new Error('User must be authenticated');
        }

        setLoading(true);
        setError(null);

        // Get fresh ID token
        const idToken = await getAuth().currentUser?.getIdToken(true);
        if (!idToken) {
          throw new Error('Failed to get authentication token');
        }

        console.log('Got fresh ID token:', !!idToken);

        // Fetch question data
        const questionDoc = await getDoc(doc(db, 'questions', questionId));

        if (!questionDoc.exists()) {
          throw new Error('Question not found');
        }

        const data = questionDoc.data();
        console.log('Question data retrieved:', {
          hasPrice: !!data.price,
          hasPlatformFee: !!data.platformFee,
          totalAmount: (data.price || 0) + (data.platformFee || 0)
        });

        // Verify ownership
        if (data.userId !== user?.uid) {
          throw new Error('Unauthorized access');
        }

        // Check if payment is already completed
        if (data.paymentStatus === 'completed') {
          navigate('/dashboard');
          return;
        }

        setQuestionData({ id: questionDoc.id, ...data });

        // Create payment intent with fresh token
        try {
          const amount = data.price + data.platformFee;

          console.log('Creating payment intent:', {
            amount,
            questionId: questionDoc.id
          });

          const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
          const result = await createPaymentIntent({
            amount,
            questionId: questionDoc.id,
            metadata: {
              responseFormat: data.responseFormat
            }
          });

          console.log('Payment intent response:', {
            success: !!result.data?.clientSecret,
            hasPaymentIntentId: !!result.data?.paymentIntentId
          });

          if (!result.data?.clientSecret) {
            throw new Error('Invalid response from payment service');
          }

          setClientSecret(result.data.clientSecret);

        } catch (err) {
          console.error('Payment intent error:', {
            code: err.code,
            message: err.message,
            details: err.details
          });
          throw new Error(err.message || 'Payment service error');
        }

      } catch (err) {
        console.error('Error:', {
          name: err.name,
          message: err.message,
          stack: err.stack
        });
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchQuestionData();
    }
  }, [questionId, user?.uid, navigate]);
  const handlePaymentSuccess = async (paymentIntent) => {
    try {
      setLoading(true);

      // Update question document
      await updateDoc(doc(db, 'questions', questionId), {
        paymentStatus: 'completed',
        paymentIntent: paymentIntent.id,
        status: 'pending',
        updatedAt: serverTimestamp(),
        lastUpdatedBy: user.uid,
        paymentMetadata: {
          amount: paymentIntent.amount,
          currency: paymentIntent.currency,
          paymentMethod: paymentIntent.payment_method,
          created: serverTimestamp()
        }
      });

      // Navigate to success page
      navigate(`/payment-success/${paymentIntent.id}`);
    } catch (error) {
      console.error('Error updating payment status:', error);
      setError('Failed to process payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentError = (error) => {
    console.error('Payment error:', error);
    setError(error.message || 'An error occurred during payment. Please try again.');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <AuthenticatedHeader user={user} onSignOut={onSignOut} />

      <div className="pt-20 pb-12">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-3xl">
          {/* Back Button */}
          <button
            onClick={() => navigate('/ask')}
            className="flex items-center gap-2 text-gray-600 hover:text-purple-600 transition-colors mb-8"
          >
            <ArrowLeft className="w-4 h-4" />
            Back to Question Form
          </button>

          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg flex items-center gap-2">
              <AlertCircle className="w-5 h-5" />
              <p>{error}</p>
            </div>
          )}

          <div className="bg-white rounded-2xl shadow-xl border border-gray-100 overflow-hidden">
            {/* Order Summary Section */}
            <OrderSummary questionData={questionData} loading={loading} />

            {/* Payment Section */}
            <div className="p-6 space-y-6">
              <div className="space-y-4">
                <h2 className="font-semibold text-gray-900">Complete Your Order</h2>

                {/* Payment Form */}
                <div className="bg-white rounded-lg">
                  {clientSecret ? (
                    <PaymentMethods isDev={isDev}>
                    <Elements
                      stripe={stripePromise}
                      options={{
                        clientSecret,
                        appearance: {
                          theme: 'stripe',
                          variables: {
                            colorPrimary: '#7c3aed',
                            colorBackground: '#ffffff',
                            colorText: '#1f2937',
                            colorDanger: '#ef4444',
                          },
                        },
                      }}
                    >
                      <PaymentForm
                        amount={(questionData?.price || 0) + (questionData?.platformFee || 0)}
                        onSuccess={handlePaymentSuccess}
                        onError={handlePaymentError}
                      />
                    </Elements>
                  </PaymentMethods>
                  ) : (
                    <div className="text-center py-8">
                      <AlertCircle className="w-8 h-8 text-gray-400 mx-auto mb-4" />
                      <p className="text-gray-600">
                        {loading ? 'Loading payment form...' : 'Unable to load payment form.'}
                      </p>
                      {!loading && (
                        <p className="text-sm text-gray-500 mt-1">Please try refreshing the page.</p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Security Note */}
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Shield className="w-4 h-4" />
                <span>
                  {isDev
                    ? 'Test mode - no real payments will be processed'
                    : 'Your payment information is secure and encrypted'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CheckoutPage.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  onSignOut: PropTypes.func.isRequired
};

export default CheckoutPage;
// src/components/auth/AuthModal.jsx
import { useState } from 'react';
import { X, Mail, Lock, Eye, EyeOff, Loader } from 'lucide-react';
import { auth, db } from '../../config/firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  serverTimestamp,
  getDoc 
} from 'firebase/firestore';
import GoogleIcon from '../icons/GoogleIcon';
import PropTypes from 'prop-types';

const AuthModal = ({ isOpen, onClose, onSuccess }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const createUserDocument = async (user, additionalData = {}) => {
    if (!user) return;

    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      const userData = {
        // Basic Info
        email: user.email,
        displayName: user.displayName || additionalData.displayName || null,
        photoURL: user.photoURL || null,
        emailVerified: user.emailVerified,
        
        // Timestamps
        createdAt: serverTimestamp(),
        lastLoginAt: serverTimestamp(),
        
        // Account Status & Stats
        accountStatus: 'active',
        totalQuestions: 0,
        totalSpent: 0,
        totalAnswers: 0,
        reputation: 0,
        
        // Questions and Answers
        questions: [],
        answers: [],
        
        // User Preferences
        preferences: {
          emailNotifications: true,
          anonymousDefault: true,
          language: 'en',
          theme: 'light'
        },
        
        // Privacy Settings
        privacySettings: {
          showEmail: false,
          showActivity: true,
          allowMessages: true
        },
        
        // System Metadata
        metadata: {
          signUpMethod: additionalData.signUpMethod || 'email',
          platform: 'web',
          version: '1.0',
          lastUpdated: serverTimestamp()
        }
      };

      try {
        await setDoc(userRef, userData);
        return userData;
      } catch (error) {
        console.error('Error creating user document:', error);
        throw error;
      }
    } else {
      // Update last login time and other relevant fields
      const updateData = {
        lastLoginAt: serverTimestamp(),
        emailVerified: user.emailVerified,
        'metadata.lastUpdated': serverTimestamp()
      };

      if (user.photoURL) {
        updateData.photoURL = user.photoURL;
      }
      if (user.displayName) {
        updateData.displayName = user.displayName;
      }

      try {
        await setDoc(userRef, updateData, { merge: true });
        const updatedSnap = await getDoc(userRef);
        return updatedSnap.data();
      } catch (error) {
        console.error('Error updating user document:', error);
        throw error;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    try {
      let userCredential;
      
      if (isLogin) {
        // Sign In
        userCredential = await signInWithEmailAndPassword(
          auth, 
          formData.email, 
          formData.password
        );
        
        // Update user document with new login timestamp
        const userData = await createUserDocument(userCredential.user, {
          signUpMethod: 'email'
        });

        onSuccess({ 
          ...userData,
          uid: userCredential.user.uid,
        });
      } else {
        // Sign Up
        userCredential = await createUserWithEmailAndPassword(
          auth, 
          formData.email, 
          formData.password
        );
        
        // Send email verification
        await sendEmailVerification(userCredential.user);
        
        // Create initial user document
        const userData = await createUserDocument(userCredential.user, {
          signUpMethod: 'email'
        });

        onSuccess({ 
          ...userData,
          uid: userCredential.user.uid,
        });
      }
      
      onClose();
    } catch (err) {
      console.error('Auth error:', err);
      
      // Handle different error codes with user-friendly messages
      const errorMessages = {
        'auth/email-already-in-use': 'This email is already registered. Please sign in instead.',
        'auth/weak-password': 'Password should be at least 6 characters',
        'auth/invalid-email': 'Please enter a valid email address',
        'auth/user-disabled': 'This account has been disabled. Please contact support.',
        'auth/user-not-found': 'No account found with this email. Please sign up instead.',
        'auth/wrong-password': 'Incorrect password. Please try again.',
        'auth/too-many-requests': 'Too many attempts. Please try again later.',
        'auth/network-request-failed': 'Network error. Please check your connection.'
      };

      setError(errorMessages[err.code] || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      
      const result = await signInWithPopup(auth, provider);
      
      // Create/update user document
      const userData = await createUserDocument(result.user, {
        signUpMethod: 'google'
      });

      onSuccess({ 
        ...userData,
        uid: result.user.uid,
      });
      
      onClose();
    } catch (err) {
      console.error('Google sign in error:', err);
      
      const errorMessages = {
        'auth/popup-closed-by-user': 'Sign in was cancelled',
        'auth/popup-blocked': 'Pop-up was blocked by your browser. Please enable pop-ups and try again.',
        'auth/cancelled-popup-request': 'Sign in was cancelled',
        'auth/account-exists-with-different-credential': 'An account already exists with the same email address but different sign-in credentials. Please sign in using the original method.'
      };

      setError(errorMessages[err.code] || 'Failed to sign in with Google. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl max-w-md w-full p-6 relative">
        {/* Close Button */}
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <X className="w-5 h-5" />
        </button>

        {/* Header */}
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-purple-900 mb-2">
            {isLogin ? 'Welcome Back!' : 'Create Account'}
          </h2>
          <p className="text-gray-600">
            {isLogin 
              ? 'Enter your details to sign in' 
              : 'Get started with your free account'}
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-50 text-red-500 p-3 rounded-lg mb-4 text-sm">
            {error}
          </div>
        )}

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <div className="relative">
              <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type="email"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value.trim() })}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
                placeholder="your@email.com"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <div className="relative">
              <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              <input
                type={showPassword ? 'text' : 'password'}
                required
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-all"
                placeholder="••••••••"
                minLength={6}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                {showPassword ? (
                  <EyeOff className="w-5 h-5" />
                ) : (
                  <Eye className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full py-2 rounded-lg transition-colors flex items-center justify-center gap-2
              ${loading 
                ? 'bg-purple-400 cursor-not-allowed' 
                : 'bg-purple-600 hover:bg-purple-700'}
              text-white`}
          >
            {loading ? (
              <>
                <Loader className="w-5 h-5 animate-spin" />
                Please wait...
              </>
            ) : (
              isLogin ? 'Sign In' : 'Create Account'
            )}
          </button>
        </form>

        {/* Divider */}
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or continue with</span>
          </div>
        </div>

        {/* Google Sign In */}
        <button
          onClick={handleGoogleSignIn}
          disabled={loading}
          className="w-full border border-gray-300 text-gray-700 py-2 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center gap-2"
        >
          <GoogleIcon />
          Continue with Google
        </button>

        {/* Toggle Login/Register */}
        <p className="text-center mt-6 text-gray-600">
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <button
            type="button"
            onClick={() => {
              setIsLogin(!isLogin);
              setError('');
              setFormData({ email: '', password: '' });
            }}
            className="text-purple-600 hover:text-purple-700 font-medium"
          >
            {isLogin ? 'Sign up' : 'Sign in'}
          </button>
        </p>
      </div>
    </div>
  );
};

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default AuthModal;
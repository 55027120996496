import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';
import AuthenticatedHeader from './components/auth/AuthenticatedHeader';

// Pages
import LandingPage from './pages/LandingPage';
import AskQuestionPage from './pages/AskQuestionPage';
import DashboardPage from './pages/DashboardPage';
import CheckoutPage from './pages/CheckoutPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import ProfilePage from './pages/ProfilePage';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notifications] = useState([]); // Add your notifications state management here

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUser({
          email: user.email,
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL
        });
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleAuthSuccess = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleUpdateProfile = async (profileData) => {
    try {
      // Implement your profile update logic here
      console.log('Updating profile:', profileData);
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  // In App.jsx
return (
  <div>
    {isAuthenticated && (
      <AuthenticatedHeader 
        user={user}
        onSignOut={handleSignOut}
        notifications={notifications}
        cartItemCount={0}
      />
    )}
    <Routes>
      <Route 
        path="/" 
        element={
          <LandingPage 
            isAuthenticated={isAuthenticated}
            user={user}
            onAuthSuccess={handleAuthSuccess}
            onSignOut={handleSignOut}
          />
        } 
      />
      <Route 
        path="/ask" 
        element={
          <AskQuestionPage 
            user={user}
            onSignOut={handleSignOut}
          />
        } 
      />
      <Route 
        path="/dashboard" 
        element={
          <DashboardPage 
            user={user}
            onSignOut={handleSignOut}
          />
        } 
      />
      <Route 
        path="/profile" 
        element={
          <ProfilePage 
            user={user} 
            onUpdateProfile={handleUpdateProfile}
          />
        } 
      />
      <Route 
        path="/checkout/:questionId" 
        element={
          <CheckoutPage 
            user={user}
            onSignOut={handleSignOut}
          />
        } 
      />
      <Route 
        path="/payment-success/:paymentIntentId" 
        element={
          <PaymentSuccessPage 
            user={user}
            onSignOut={handleSignOut}
          />
        } 
      />
    </Routes>
  </div>
);
};

export default App;
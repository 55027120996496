// src/components/landing/LandingPage.jsx
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Info } from 'lucide-react';
import PropTypes from 'prop-types';
import Navigation from '../components/landing/Navigation';
import AuthenticatedHeader from '../components/auth/AuthenticatedHeader';
import QuestionFormCard from '../components/questions/QuestionFormCard';
import TrustBadges from '../components/landing/TrustBadges';
import HowItWorks from '../components/landing/HowItWorks';
import Features from '../components/landing/Features';
import Testimonials from '../components/landing/Testimonials';
import Pricing from '../components/landing/Pricing';
import AuthModal from '../components/auth/AuthModal';
import Footer from '../components/Footer';

const LandingPage = ({isAuthenticated, user, onAuthSuccess, onSignOut }) => {
  const navigate = useNavigate(); 
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [draftQuestion, setDraftQuestion] = useState(null);

  const notifications = [
    { message: "Your question has been answered!", time: "2 hours ago" }
  ];

  // In LandingPage.jsx, modify the handleQuestionSubmit function:

const handleQuestionSubmit = (formData) => {
  if (isAuthenticated) {
    // Navigate to the detailed form page with the draft data
    navigate('/ask', { state: { draftQuestion: formData } });
  } else {
    // Show auth modal for unauthenticated users
    setDraftQuestion(formData);
    setIsAuthModalOpen(true);
  }
};
  const handleAuthSuccess = (userData) => {
    setIsAuthModalOpen(false);
    onAuthSuccess(userData); // Pass the userData up to App.jsx
    if (draftQuestion) {
      // Handle the draft question submission
      console.log('Submitting question:', draftQuestion);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
     {isAuthenticated ? (
        <AuthenticatedHeader 
          user={user}
          onSignOut={onSignOut}
          notifications={notifications}
          onNotificationClick={(notification) => {
            console.log('Notification clicked:', notification);
          }}
        />
      ) : (
        <Navigation 
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          handleAskQuestion={() => setIsAuthModalOpen(true)}
        />
      )}
      
      {/* Hero Section with Embedded Form */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl pt-32 pb-16">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Hero Content */}
          <div>
            <h1 className="text-4xl sm:text-5xl font-bold text-purple-900 mb-6 leading-tight">
              Ask That Burning Question,
              <span className="text-purple-600"> Anonymously</span>
            </h1>
            <p className="text-lg text-gray-600 mb-8 leading-relaxed">
              Ever wanted to ask someone something but felt too awkward? 
              We'll ask for you, and get you answers - either in video or writing.
            </p>
            <div className="flex flex-wrap gap-4 mb-8">
              <div className="flex items-center gap-2">
                <span className="bg-purple-100 p-2 rounded-full">
                  <Shield className="w-4 h-4 text-purple-600" />
                </span>
                <span className="text-gray-600">100% Anonymous</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="bg-purple-100 p-2 rounded-full">
                  <Info className="w-4 h-4 text-purple-600" />
                </span>
                <span className="text-gray-600">24/7 Support</span>
              </div>
            </div>
          </div>

          {/* Question Form Card */}
          <QuestionFormCard onSubmit={handleQuestionSubmit} />
        </div>
      </div>

      <TrustBadges />
      <HowItWorks />
      <Features />
      <Testimonials />
      <Pricing />
      
      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => setIsAuthModalOpen(false)} 
        onSuccess={handleAuthSuccess}
      />
      
      <Footer />
    </div>
  );
};

LandingPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    // Add other user properties you expect
  }),
  onAuthSuccess: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired
};


export default LandingPage;
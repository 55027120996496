// src/pages/AskQuestionPage.jsx
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import DetailedQuestionForm from '../components/questions/DetailedQuestionForm';
import AuthenticatedHeader from '../components/auth/AuthenticatedHeader';
import PropTypes from 'prop-types';

const AskQuestionPage = ({ user, onSignOut }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const draftQuestion = location.state?.draftQuestion;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (formData) => {
    setSubmitting(true);
    setError(null);

    try {
      // Calculate platform fee (10% of price)
      const platformFee = Math.max(formData.price * 0.10, 0.50);

      // Create the question document
      const questionData = {
        // Question details
        question: formData.question,
        recipient: formData.recipient,
        recipientEmail: formData.recipientEmail,
        responseFormat: formData.responseFormat,
        price: Number(formData.price),
        platformFee: Number(platformFee.toFixed(2)),
        
        // Metadata
        status: 'pending', // Changed from 'draft' to 'pending' to match Firestore rules
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        
        // User info
        userId: user.uid,
        userEmail: user.email,
        
        // Response tracking
        response: null,
        respondedAt: null,
        
        // Payment status
        paymentStatus: 'pending',
        paymentIntent: null,
        transactionId: null,
        
        // System fields
        isAnonymous: true,
        systemMetadata: {
          version: '1.0',
          platform: 'web',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        }
      };

      // Add the question to Firestore
      const questionsRef = collection(db, 'questions');
      const docRef = await addDoc(questionsRef, questionData);

      // Navigate to checkout with the question ID
      navigate(`/checkout/${docRef.id}`);

    } catch (error) {
      console.error('Error submitting question:', error);
      setError('Failed to submit question. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  // If there's no user, redirect to home
  if (!user) {
    navigate('/', { replace: true });
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AuthenticatedHeader 
        user={user}
        onSignOut={onSignOut}
      />
      <div className="pt-20">
        {error && (
          <div className="max-w-3xl mx-auto mb-4">
            <div className="bg-red-50 text-red-600 px-4 py-3 rounded-lg">
              {error}
            </div>
          </div>
        )}
        
        <DetailedQuestionForm 
          initialData={draftQuestion}
          onSubmit={handleSubmit}
          isSubmitting={submitting}
        />
      </div>
    </div>
  );
};

AskQuestionPage.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  onSignOut: PropTypes.func.isRequired
};

export default AskQuestionPage;
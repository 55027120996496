import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  MessageSquare, 
  User, 
  Clock, 
  Bell, 
  LogOut,
  ChevronDown,
  Search,
  ShoppingCart,
  FileText
} from 'lucide-react';

const AuthenticatedHeader = ({ 
  user, 
  onSignOut = () => {}, 
  notifications = [], 
  cartItemCount = 0
}) => {
  const navigate = useNavigate();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const handleSignOut = () => {
    onSignOut?.();
    setIsProfileOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsProfileOpen(false); // Close dropdown after navigation
    setIsNotificationsOpen(false); // Close notifications if open
  };

  const handleNotificationClick = (notification) => {
    // Handle notification click based on type
    if (notification.type === 'question') {
      handleNavigation('/dashboard');
    } else if (notification.type === 'payment') {
      handleNavigation('/dashboard'); // Changed from order-status to dashboard
    }
    setIsNotificationsOpen(false);
  };

  return (
    <nav className="fixed top-0 w-full bg-white/80 backdrop-blur-md shadow-sm z-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div className="flex items-center justify-between h-16">
          {/* Left side - Logo and Search */}
          <div className="flex items-center gap-8">
            <button 
              onClick={() => handleNavigation('/')}
              className="flex items-center gap-2 hover:opacity-80 transition-opacity"
            >
              <MessageSquare className="w-8 h-8 text-purple-600" />
              <span className="text-xl font-bold text-purple-900">Snarq</span>
            </button>
            
            <div className="hidden md:flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-full">
              <Search className="w-4 h-4 text-gray-400" />
              <input 
                type="text" 
                placeholder="Search your questions..." 
                className="bg-transparent border-none outline-none text-sm w-64"
              />
            </div>
          </div>

          {/* Right side - Navigation and Profile */}
          <div className="flex items-center gap-4">
            {/* Navigation Items */}
            <div className="hidden md:flex items-center gap-6">
              <button 
                onClick={() => handleNavigation('/dashboard')}
                className="flex items-center gap-2 text-gray-600 hover:text-purple-600 transition-colors"
              >
                <FileText className="w-5 h-5" />
                <span>My Questions</span>
              </button>
              <button 
                onClick={() => handleNavigation('/dashboard')} 
                className="flex items-center gap-2 text-gray-600 hover:text-purple-600 transition-colors"
              >
                <Clock className="w-5 h-5" />
                <span>Order Status</span>
              </button>
            </div>

            {/* Cart Button */}
            <button 
              onClick={() => handleNavigation('/checkout')}
              className="relative p-2 text-gray-600 hover:text-purple-600 transition-colors"
            >
              <ShoppingCart className="w-5 h-5" />
              {cartItemCount > 0 && (
                <span className="absolute -top-1 -right-1 w-5 h-5 bg-purple-600 text-white text-xs rounded-full flex items-center justify-center">
                  {cartItemCount}
                </span>
              )}
            </button>

            {/* Notifications */}
            {/* ... rest of notifications code remains the same ... */}

            {/* Profile Dropdown */}
            <div className="relative">
              <button 
                onClick={() => setIsProfileOpen(!isProfileOpen)}
                className="flex items-center gap-2 p-2 text-gray-600 hover:text-purple-600 transition-colors"
              >
                <div className="w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center">
                  <User className="w-5 h-5 text-purple-600" />
                </div>
                <ChevronDown className="w-4 h-4" />
              </button>

              {isProfileOpen && ( 
                <div className="absolute right-0 mt-2 w-56 bg-white rounded-xl shadow-lg border border-gray-100 py-2">
                  <div className="px-4 py-2 border-b border-gray-100">
                    <p className="font-semibold">{user?.email}</p>
                  </div>
                  
                  <button 
                    onClick={() => handleNavigation('/profile')}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2"
                  >
                    <User className="w-4 h-4" />
                    Profile & Settings
                  </button>
                  
                  <button 
                    onClick={() => handleNavigation('/dashboard')}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2"
                  >
                    <FileText className="w-4 h-4" />
                    My Questions
                  </button>
                  
                  <button 
                    onClick={handleSignOut}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2 text-red-600"
                  >
                    <LogOut className="w-4 h-4" />
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AuthenticatedHeader;
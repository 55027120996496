// src/config/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAqreTcTF1j9l0c6JT9mn4IZpW9eYl0SoM",
  authDomain: "snarkyquestions.firebaseapp.com",
  projectId: "snarkyquestions",
  storageBucket: "snarkyquestions.firebasestorage.app",
  messagingSenderId: "989677808259",
  appId: "1:989677808259:web:1f26b09ead7182065789b6",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app, 'us-central1'); // Specify region
export const db = getFirestore(app);

export default app;